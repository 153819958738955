<template>
    <div class="w-100 position-relative">
      <div v-if="!is_in_searcher">
        <search @changeNetwork="changeNetwork" @open_side_summary="openSummaryProfile"></search>
      </div>

      <b-row class="mb-05">
        <b-col class="col-12 col-lg-12">
          <div class="d-flex">
            <h4 
              class="text-left d-flex align-items-center m-0 break-word" 
            >
              {{ $t('creator.featuredPicks') }}
              <span class="text-country" v-if="!focus_name_country" @click="focus_name_country = true">{{country.name}}</span>
              <feather-icon icon="ChevronDownIcon" size="18" class="cursor-pointer" v-if="!focus_name_country"></feather-icon>
            </h4>
            <div v-if="focus_name_country" class="h-100">
              <div v-for="(location, index) in audience_locations" :key="index">
                <audience-location-filter
                  ref="audience_location_filter"
                  :key="change_audience_location"
                  :show_remove="false"
                  :show_slider="false"
                  :index="index"
                  :location="location"
                  :only_countries="true"
                  :show_reset_location="false"
                  :show_loader="false"
                  :is_in_analyzer="true"
                  @changeLocationAudienceChild="changeLocationAudience"
                  @blur_action="blurActionSuggestion"
                />
              </div>
          </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <div class="container-loader-searcher" v-if="loader_location">
          <b-spinner class="spinner-table-searcher"></b-spinner>
        </div>
        <b-col class="col-12 col-lg-6 mt-1" v-for="card in cards" :key="card.hash" @click="searcher(card)">
          <card-secondary
            class="cursor-pointer"
            :textInfo="card.slug"
            :mention="card.title"
            :colorCard="card.color"
            :images="card.avatars"
          >
          </card-secondary>
        </b-col>
        
      </b-row>

      <sidebar-search-new
        :open_sidebar="open_sidebar" 
        :key="update_sidebar" 
        :data="data_sidebar"
        :network="select_network"
        class="open-sidebar-search"
        v-if="open_sidebar"
        @get_profile_url="getProfileUrl"
      >
      </sidebar-search-new>
    </div>
</template>

<script>
import {
  BSpinner,
  BRow,
  BCol,
} from "bootstrap-vue";
import { getUrlCategory } from '@/libs/utils/urls';
import { getMainInfluencers, getCategories } from '@/services/search'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  name: 'analyzerDashboard',
  components: {
    BSpinner,
    BRow,
    BCol,
    Search: () => import('@/views/search/search.vue'),
    cardSecondary: () => import('@/views/pages/analyzer/cardSecondary.vue'),
    AudienceLocationFilter: () => import('@/views/buscador/filters/audience_locations.vue'),
    SidebarSearchNew: () => import('@/views/buscador/SidebarSearchNew.vue'),
  },
  data() {
    return {
      loader_location: false,
      network: {value: 'instagram', id: '1'},
      networks: [
        {value: 'instagram', id: '1'},
        {value: 'tiktok', id: '3'},
        {value: 'twitter', id: '5'},
        {value: 'youtube', id: '2'},
        {value: 'twitch', id: '4'}
      ],
      sort_options: [
        {key: 'most_followed', sort_type: 'fc', sort_order: 'desc'},
        {key: 'fast_growing', sort_type: 'sbs_grth', sort_order: 'desc'},
        {key: 'most_commented', sort_type: 'cr', sort_order: 'desc'},
        {key: 'influencers', sort_type: 'fc', sort_order: 'desc'},
        {key: 'best_quality', sort_type: 'er', sort_order: 'desc'},
        {key: 'best_quality_2', sort_type: 'aqs', sort_order: 'desc'},
        {key: 'most_liked', sort_type: 'likes', sort_order: 'desc'},
        {key: 'fast_growing_women', sort_type: 'sbs_grth', sort_order: 'desc'},
        {key: 'fast_growing_men', sort_type: 'sbs_grth', sort_order: 'desc'},
        {key: 'most_er', sort_type: 'er', sort_order: 'desc'},
        {key: 'most_viewed', sort_type: 'pv_a', sort_order: 'desc'},
        {key: 'most_streamed', sort_type: 'hours_streamed', sort_order: 'desc'},
        {key: 'most_viewed_4', sort_type: 'live_viewers_avg', sort_order: 'desc'},
      ],
      getUrlCategory,
      main_country: 3996063,
      cards: [],
      update_location: false,
      audience_locations: [{}],
      change_audience_location: false,
      main_influencers: [],
      country: {
        id: 3996063,
        name: 'Mexico',
        type: 0
      },
      focus_name_country: false,
      open_sidebar: false,
      update_sidebar: 0,
      data_sidebar: {},
      select_network: 'instagram'
    }
  },
  props: {
    is_in_searcher: {
      type: Boolean,
      default: false,
    }
  },
  created() {
    this.cards = [];
    this.getMainInfluencers()
  },
  destroyed() {
  },
  methods: {
    openSummaryProfile(item) {
      getCategories(item.type).then(response => {
        this.data_sidebar = {
          'username': {
            'name': {
              username: item.type !== 'youtube' ? item.username : item.channel_id,
              ava: item.avatar_url,
              avatar: item.avatar_url,
              fullname: item.title
            }
          },
          followers: item.subscribers_count,
          categories: {
            all_categories: item.type !== 'youtube' ? response.labels : response.thematics
          }
        }
        this.select_network = item.type;

        this.open_sidebar = true;
        this.update_sidebar += 1;
      });
    },
    blurActionSuggestion() {
      this.focus_name_country = false;
    },
    changeNetwork(network) {
      this.network = this.networks.find(i => i.value === network)
      this.getMainInfluencers()
    },
    changeLocationAudience(data) {
      this.country = data.location
      this.getMainInfluencers()
      this.focus_name_country = false;
    },
    getMainInfluencers() {
      this.loader_location = true;
      getMainInfluencers(this.country.id).then(response => {
        if (response.error) {
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title: this.$t('campaigns.error_status'),
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: this.$t('search.workingIssue')
              }
            })
          })
          this.loader_location = false;
          return;
        }
        this.main_influencers = response.searches
        this.formatCards()
      })
    },
    formatCards() {
      this.cards = []
      this.main_influencers[this.network.id].forEach(item => {
        this.cards.push(item);
      })
      this.loader_location = false;
    },
    searcher(card) {
      const exist_locations = Object.keys(card.search).find(k => k === 'audience_location')

      if (exist_locations) {
        card.search['audience_location'][0]['name'] = this.country.name
      }

      const exist_influencer_locations = Object.keys(card.search).find(k => k === 'influencer_location')

      if (exist_influencer_locations) {
        card.search['influencer_location'][0]['name'] = this.country.name
      }

      let slug = card.slug

      if (this.network.id === '4' && slug === 'most_viewed') slug = `${slug}_4`
      if (this.network.id === '2' && slug === 'best_quality') slug = `${slug}_2`

      const sort = this.sort_options.find(s => s.key === slug)

      if (sort) {
        card.search['sort_type'] = sort.sort_type
        card.search['sort_order'] = sort.sort_order
      } else {
        card.search['sort_type'] = 'sbs_grth'
        card.search['sort_order'] = 'desc'
      }
      const search = JSON.stringify(card.search);

      const hash = btoa(search);

      this.$router.push({name: 'auth-search', query: {hash}}).catch(() => {});
    }
  },
} 
</script>

<style scoped>
.container-loader-searcher {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner-table-searcher {
  height: 6em;
  width: 6em;
  color: #acacac;
}
.text-country {
  color: #0096fe;
  cursor: pointer;
  margin: 0em 0.2em 0 0.3em;
}
.mb-05 {
  margin-bottom: .5em;
}
</style>